import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router';
import { withSession } from '../_context/session.context';
import { UserService } from '../_service';
import { createMeetingPageRoute } from './meeting.page';
import NameUpdate from './name-update.component';

export const JOIN_MEETING_ROUTE = '/join/:meetingId';

export const createJoinMeetingLink = meetingId => `/join/${meetingId}`;

function JoinMeetingComponent({ user, setUser }) {

    // get meeting id
    const { meetingId } = useParams();

    // get token
    const sp = new URLSearchParams(window.location.search);
    const token = sp.get('token');

    const port = window.location.port;

    const [failedLoadingUser, setFailedLoadingUser] = useState(false);
    const [isUserLoadComplete, setIsUserLoadComplete] = useState(false);

    // for first time, fetch the user as well
    useEffect(() => {
        // load the user
        if (token) {
            UserService.getUserProfile({ token })
                .then(({ user }) => {
                    setUser({ ...user, token });
                    setIsUserLoadComplete(true);
                })
                .catch(error => {
                    setFailedLoadingUser(true);
                    setIsUserLoadComplete(true);
                    console.error({ error });
                });
        } else {
            if (!user) {
                setUser({ name: "Anonymous User", token: "" });
            }

            setIsUserLoadComplete(true);
        }
    }, [token]);


    // check if failed loading user
    if (failedLoadingUser) {
        return (
            <div className="LostPage bg-black text-white h-screen flex justify-center items-center">
                <span>Session invalid!</span>
            </div>
        )
    }


    // if we have a token, show that loading details
    if (!isUserLoadComplete) {
        return (
            <div className="LostPage bg-black text-white h-screen flex justify-center items-center">
                <span>Please wait while we join you in!</span>
            </div>
        );
    } else {
        // if not user redirect to get user
        if (!user) {
            // redirect to main app
            window.location.href = process.env.REACT_APP_CLIENT_URL + (port ? (":" + (port - 1)) : "") + "?redirect=" + window.location.href;
            return (
                <div className="LostPage bg-black text-white h-screen flex justify-center items-center">
                    <span>Session invalid, please click back on link from webapp!</span>
                </div>
            )
        }
    }

    // if user name is missing, let the user update it
    if (!user.name || user.name === "New User") {
        return (
            <NameUpdate />
        )
    }

    // if we have user, forward to meeting
    return <Redirect to={createMeetingPageRoute(meetingId)} />

}

const JoinMeeting = withSession(JoinMeetingComponent);
export default JoinMeeting;
