import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { DyteService } from '../_service';
import { withSession } from '../_context/session.context';
import { Loader } from './loader.component';
import { createJoinMeetingLink } from './join-meeting.page';
import { Helmet } from 'react-helmet';


import { useDyteClient } from '@dytesdk/react-web-core';
import { DyteMeeting, generateConfig } from '@dytesdk/react-ui-kit';

export const MEETING_PAGE_ROUTE = "/:meetingId";

export const createMeetingPageRoute = meetingId => `/${meetingId}`;

function MeetingPageComponent({ user, setUser }) {

    // get meeting id
    const { meetingId } = useParams();

    // get token
    const sp = new URLSearchParams(window.location.search);
    const token = sp.get('token');

    // if user name is not update, let the user update it first, if token is there reauth user
    if (!user || !user.name || user.name === "New User" || token) {
        return (
            <Redirect to={createJoinMeetingLink(meetingId) + `?token=${token ? token : ""}`} />
        )
    }

    return (
        <div className="MeetingPage">
            <MeetingComponent
                user={user} />
        </div>
    )

}

const MeetingPage = withSession(MeetingPageComponent);

export default MeetingPage;


function MeetingComponent({ user }) {

    // get 
    const { meetingId } = useParams();

    // dyte obj
    const [meetingObj, initMeeting] = useDyteClient();
    const [config, setConfig] = useState(undefined);

    // meeting
    const [isLoading, setIsLoading] = useState(false);
    const [meeting, setMeeting] = useState(null);
    const [message, setMessage] = useState("");

    const [userName, setUserName] = useState("Anonymous User");
    const [newUserName, setNewUserName] = useState("");

    const [meetingLeft, setMeetingLeft] = useState(false);

    const meetingRef = useRef(null);
    /**
     * meeting has the following
     * 
     * authToken
     * clientId
     * endingKey
     * isBeingRecorded
     * meetingId
     * roomName
     */

    // dyteinit
    useEffect(() => {
        if (meeting?.authToken) {
            initMeeting({
                authToken: meeting?.authToken,
                defaults: {
                    audio: false,
                    video: false,
                },
            })
        }
    }, [meeting]);

    // config init
    useEffect(() => {
        if (!meetingObj || config !== undefined) return;
        const g = generateConfig(meetingObj.self.config, meetingObj);
        const { config: meetingConfig } = g;
        meetingConfig.config = {
            notifications: {
                participant_joined: false,
                participant_left: false,
                participant_joined_waitlist: false,
                chat: false,
                polls: false,
                webinar: false,
                tab_sync: false,
            },
            notification_sounds: {
                participant_joined: false,
                participant_left: false,
                participant_joined_waitlist: false,
                chat: false,
                polls: false,
                webinar: false,
                tab_sync: false,
            },
        };
        setConfig(meetingConfig); // set the config in state
    }, [meetingObj])

    useEffect(() => {
        if (user) {
            setUserName(user.name);
        }
    }, [user]);

    useEffect(() => {
        setIsLoading(true);
        // get meeting only if username is set
        if (userName !== "Anonymous User") {
            // get meeting info
            DyteService.getMeetingData(user, meetingId, userName)
                .then(response => {
                    // update them meeting data
                    setIsLoading(false);
                    setMeeting(response.payload);
                })
                .catch(err => {
                    const m = err && err.response && err.response.data && err.response.data.message;
                    setMessage(m ? m : "Something went wrong!");
                    setIsLoading(false);

                });
        }
    }, [meetingId, user, userName]);

    // enable wake lock
    useEffect(() => {
        if (config) {
            enableScreenWakeLock();
        }
    }, [config]);

    const enableScreenWakeLock = async () => {
        // Create a reference for the Wake Lock.
        let wakeLock = null;

        // create an async function to request a wake lock
        try {
            wakeLock = await navigator.wakeLock.request("screen");
            console.log("Wake Lock is active!");
        } catch (err) {
            // The Wake Lock request has failed - usually system related, such as battery.
            console.log("Wake Lock request failed - ", `${err.name}, ${err.message}`);
        }

        return wakeLock;
    }

    const newNameHasErrors = !newUserName || !newUserName.trim();

    const proceed = () => {
        if (newNameHasErrors) {
            return;
        } else {
            setUserName(newUserName);
        }
    }

    if (userName === "Anonymous User") {
        return (
            <div className="MeetingPage flex flex-col justify-center items-center py-10 h-screen">
                <input placeholder="Enter name to continue" className="rounded w-64 px-4 py-1 border" value={newUserName} onChange={e => setNewUserName(e.target.value)} />
                <button className={"bg-blue-500 text-white px-4 py-1 rounded mt-2 " + (newNameHasErrors ? "opacity-50 cursor-not-allowed" : "")} onClick={() => proceed()}>Join Meeting</button>
            </div>
        );
    }

    if (!meeting || !config || meetingLeft) {
        return (
            <div className="MeetingPage flex flex-col justify-center items-center py-10">
                {
                    isLoading || !config ? (
                        <Loader />
                    ) : <></>
                }
                <span className="message">{meetingLeft ? "Meeting left, please close the window!" : message}</span>
            </div>
        )
    }

    return (
        <>
            {
                meeting.favicon ? (
                    <Helmet>
                        <link rel="icon" href={meeting.favicon} />
                        <title>{meeting.communityName}</title>
                    </Helmet>
                ) : <></>
            }
            {/* // https://docs.dyte.io/react-ui-kit/components/dyte-notifications#notifications-popup */}
            {
                config ? (
                    <DyteMeeting
                        meeting={meetingObj}
                        config={config}
                    />
                ) : <></>
            }
        </>
    );
}